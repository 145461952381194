<template>
	<v-container
		id="Users"
		fluid
		tag="section">
		<v-row dense>
			<v-col>
				<v-card class="v-card--material true px-5 py-3">
					<card-heading
						title="Users"
						color="secondary">
						<template v-slot:default></template>
					</card-heading>

					<v-card-title>
						<v-row>
							<v-col
								cols="6"
								class="ml-auto"></v-col>
						</v-row>
					</v-card-title>
					<v-data-table
						sort-by="last_name"
						:sort-desc="true"
						must-sort
						:headers="headers"
						:items="list.data.data"
						:search="search"
						:item-class="itemClass"
						:options.sync="options"
						:server-items-length="total_records"
						:footer-props="footerProps"
						:loading="list.isLoading"
						@click:row="handleClick">
						<template #top="{ pagination }">
							<v-data-footer
								class="anton"
								:options.sync="options"
								v-bind="footerProps"
								:pagination="pagination"></v-data-footer>
						</template>
						<template v-slot:[`item.fullName`]="{ item }">
							{{ item.last_name }} {{ item.first_name }}
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import CardHeading from "../../components/shared/CardHeading.vue";
import permissions from "../../mixins/permissions";
import table from "../../mixins/table";

export default {
	mixins: [permissions, table],
	components: { CardHeading },
	data() {
		return {
			rowIsClickable: true,
			search: "",
			headers: [
				{ text: "Name", value: "fullName", align: "center" },
				{ text: "Email", value: "email", align: "center" },
			],
		};
	},
	computed: {
		...mapGetters({ list: "userList" }),
	},
	methods: {
		...mapMutations({
			setPagination: "pagination",
		}),
		...mapActions({ loadList: "getUserList" }),
		handleClick(value) {
			this.$router.push({
				name: "user-detail",
				params: {
					user_id: value._id,
					breadcrumbs: { title: `User ${value.first_name} ${value.last_name}` },
				},
			});
		},
	},
};
</script>

<style></style>
